import React from 'react';
import { PageProps, graphql } from 'gatsby';
import SEO from '../../components/SEO';
import Benefits from '../../components/Landing/Benefits/Benefits';

const PAGE_TITLE = 'Beneficios';
const PAGE_DESCRIPTION =
  'Programas de referidos, donaciones y más. Participa en nuestras diversas iniciativas que recompensan y dan valor a nuestra comunidad.';

const BenefitsPage: React.FC<PageProps> = () => {
  return (
    <>
      <SEO title={PAGE_TITLE} description={PAGE_DESCRIPTION} />
      <Benefits />
    </>
  );
};
export default BenefitsPage;
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
