import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: '#074F71',
      backgroundColor: 'transparent ',
    },
    title: {
      color: '#074F71',
      fontSize: '18px',
      paddingTop: '90px',
      paddingLeft: '0%',
      [theme.breakpoints.down(769)]: {
        paddingTop: '100px',
        paddingBottom: '30px',
      },
      [theme.breakpoints.down(500)]: {
        paddingTop: '90px',
      },
    },
    cardHeader: {
      color: '#074F71',
      fontSize: '48px',
      fontWeight: 400,
      textAlign: 'center',
      paddingTop: '30px',
      position: 'relative',
      paddingBottom: '30px',
      width: '510px',
      marginBlockEnd: '0px',
      marginBlockStart: '0px',
      [theme.breakpoints.down(769)]: {
        fontSize: '48px',
        paddingTop: '0px',
        paddingBottom: '30px',
      },
      [theme.breakpoints.down(500)]: {
        fontSize: '30px',
        textAlign: 'left',
        paddingTop: '10px',
        paddingBottom: '15px',
      },
    },
    referralButton: {
      display: 'flex',
      justifyContent: 'center',
      background: '#36ADAC',
      border: 0,
      textAlign: 'center',
      textTransform: 'inherit',
      color: '#FFFFFF',
      position: 'relative',
      borderRadius: '40px',
      fontSize: 22,
      marginTop: 10,
      width: 329,
      height: 50,
      fontFamily: `Montserrat`,
      fontWeight: `bold`,
      '&:hover': {
        background: '#074F71',
        color: '#FAB234',
        boxShadow: '0px 2px 10px #888888',
      },
      [theme.breakpoints.down(769)]: {
        width: `100%`,
      },
      [theme.breakpoints.down(500)]: {
        fontSize: 18,
        fontWeight: 800,
      },
    },
    superDigitalButton: {
      display: 'flex',
      justifyContent: 'center',
      background: '#004F71',
      border: 0,
      textAlign: 'center',
      textTransform: 'inherit',
      color: '#FFFFFF',
      position: 'relative',
      borderRadius: '40px',
      fontSize: 22,
      marginTop: 10,
      width: 329,
      height: 50,
      fontFamily: `Montserrat`,
      fontWeight: `bold`,
      '&:hover': {
        background: '#074F71',
        color: '#FAB234',
        boxShadow: '0px 2px 10px #888888',
      },
      [theme.breakpoints.down(769)]: {
        width: `100%`,
      },
      [theme.breakpoints.down(500)]: {
        fontSize: 18,
        fontWeight: 800,
      },
    },
    superDigitalImg: {
      objectPosition: '50% 10%',
    },
    benefitsCard: {
      width: '510px',
      height: '513px',
      '& .MuiCardMedia-img': {
        objectFit: 'none',
      },
      '& .MuiCardActions-root': {
        display: 'flex',
        justifyContent: 'center',
      },
      '& h2': {
        fontSize: '25px',
        color: '#004F71',
        paddingTop: '20px',
        textAlign: 'center',
        letterSpacing: '0px',
        paddingBottom: '20px',
      },
      [theme.breakpoints.down(769)]: {
        '& .MuiCardMedia-img': {
          objectFit: 'none',
        },
        '& h2': {
          fontSize: '25px',
          color: '#004F71',
          paddingTop: '0px',
          textAlign: 'center',
          letterSpacing: '0px',
          paddingBottom: '0px',
        },
      },
      [theme.breakpoints.down(500)]: {
        '& .MuiCardMedia-img': {
          objectFit: 'cover',
        },
        width: '73%',
      },
    },
    container: {
      height: `auto`,
      marginTop: 72,
      paddingTop: 0,
      [theme.breakpoints.down(769)]: {
        padding: 0,
        marginTop: 0,
        paddingTop: 0,
      },
      [theme.breakpoints.down(500)]: {
        padding: 0,
        marginTop: 0,
        paddingTop: 0,
      },
    },
    containerCenter: {
      justifyContent: 'center',
      [theme.breakpoints.down(769)]: {
        justifyContent: 'unset',
      },
    },
    firstSubSection: {
      paddingTop: '0px',
      marginBottom: '72px',
      maxWidth: '559px',
      lineHeight: '22px',
      [theme.breakpoints.down(769)]: {
        paddingLeft: 70,
        paddingTop: '60px',
        marginBottom: '10px',
        lineHeight: '22px',
      },
      [theme.breakpoints.down(500)]: {
        paddingLeft: 0,
        paddingTop: '0px',
        marginBottom: '10px',
        lineHeight: '22px',
        maxWidth: '500px',
      },
    },
    [theme.breakpoints.down(1921)]: {
      container: {
        padding: '0 6%',
      },
    },
  })
);

export default useStyles;
