import React from 'react';
import { Typography, Container, Grid, Button, Hidden } from '@material-ui/core';
import useStyles from './Benefits.style';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import ReferralImage from '../../../images/Benefits/referrals/referrals.png';
import SuperDigital from '../../../images/Benefits/superDigital/superDigital.png';
import { navigate } from 'gatsby';

const Benefits = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <section id="#benefits" className={classes.root}>
        <Container className={classes.container} maxWidth="lg">
          <Grid container>
            <div className={classes.title}>
              <strong>Beneficios</strong>
            </div>
            <Grid className={classes.containerCenter} container>
              <Grid className={classes.firstSubSection} item xs={12} md={12}>
                <h1 className={classes.cardHeader}>Programa Referidos</h1>
                <Card className={classes.benefitsCard}>
                  <CardMedia
                    component="img"
                    height="233"
                    width="446"
                    image={ReferralImage}
                    title="Referrals Program"
                  />
                  <CardContent>
                    <Typography variant="body2" color="textSecondary" component="h2">
                      Conoce Nuestro programa
                      <br /> de donaciones y se el
                      <br /> <strong>motor de cambio!</strong>
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      className={classes.referralButton}
                      onClick={() => {
                        navigate('/beneficios/programa-referidos/');
                      }}
                    >
                      ¡Saber más!
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Hidden xsUp>
                <Grid item md={6}>
                  <Grid className={classes.firstSubSection} item xs={12} md={12}>
                    <div className={classes.cardHeader}>SuperDigital</div>
                    <Card className={classes.benefitsCard}>
                      <CardMedia
                        className={classes.superDigitalImg}
                        component="img"
                        height="415"
                        width="446"
                        image={SuperDigital}
                        title="SuperDigital"
                      />
                      <CardActions>
                        <Button
                          className={classes.superDigitalButton}
                          onClick={() => {
                            navigate('/beneficios/super-digital/');
                          }}
                        >
                          ¡Lo quiero!
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Benefits;
